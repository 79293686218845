
.stage{
    font-size:24px;
    vertical-align: top;
}

.round{
    border-bottom:1px solid black;
}

.round_info_block{
    display:inline-block;
    margin:5px;
    text-align:center;
}


.round-rerolls{
    background-image: url(../img/reroll.png);
    background-size: 24px;
    width: 40px;
    height: 24px;
    background-repeat: no-repeat;
    padding-left: 20px;
    padding-top: 3px;
}

.round-xp-ups{
    background-image: url(../img/xp_up.png);
    background-size: 24px;
    width: 40px;
    height: 24px;
    background-repeat: no-repeat;
    padding-left: 20px;
    padding-top: 3px;
}

.round-gold{
    background-image: url(../img/gold.png);
    background-size: 24px;
    width: 40px;
    height: 24px;
    background-repeat: no-repeat;
    padding-left: 20px;
    padding-top: 3px;
}

.win_streak{
    text-align: left;
    padding-left: 20px;
    color:white;
    background:url("../img/win_streak.png");
    background-size:contain;
    height:20px;
    width:45px;
}

.loss_streak{
    text-align: left;
    padding-left: 20px;
    color:white;
    background:url("../img/loss_streak.png");
    background-size:contain;
    height:20px;
    width:45px;
}

.xp_up_event:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.reroll_event:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.xp_up_event{
    color: black;
    font-size: 20px;
    display: inline-block;
    width: 45px;
    height: 45px;
    text-align: center;
    background-image: url(../img/xp_up.png);
    background-size: contain;
    vertical-align: middle;
}
.reroll_event{
    color: black;
    font-size: 20px;
    display: inline-block;
    width: 45px;
    height: 45px;
    text-align: center;
    background-image: url(../img/reroll.png);
    background-size: contain;
    vertical-align: middle;
}