
.reroll_event_tile{
    font-size:12px;
    width:20px;
    height:20px;
}

.xp_up_event_tile{
    font-size:12px;
    width:20px;
    height:20px;
}


.tile-champion.img-champion{
    width:25px;
    height:25px;
}

.tile-champion.champion-border-1:before{
    position: absolute;
    content:"";
    width: 37px;
    height: 30px;
    background:url("/static/img/1stars.png");
    background-repeat:no-repeat; 
    background-position: bottom;
    margin-left: -6px;
}

.tile-champion.champion-border-2:before{
    position: absolute;
    content:"";
    width: 37px;
    height: 30px;
    background:url("/static/img/2stars.png");
    background-repeat:no-repeat; 
    background-position: bottom;
    margin-left: -6px;
}

.tile-champion.champion-border-3:before{
    position: absolute;
    content:"";
    width: 37px;
    height: 30px;
    background:url("/static/img/3stars.png");
    background-repeat:no-repeat; 
    background-position: bottom;
    margin-left: -6px;
}

.tile-trait.bronze{
    width: 25px;
    height: 25px;
    background: url(http://ddragon.canisback.com/set3/traits_transparent/bg_small.png);
    background-position: -47px;
    background-repeat: no-repeat;
}

.tile-trait.silver{
    width: 25px;
    height: 25px;
    background: url(http://ddragon.canisback.com/set3/traits_transparent/bg_small.png);
    background-position: -71px;
    background-repeat: no-repeat;
}

.tile-trait.gold{
    width: 25px;
    height: 25px;
    background: url(http://ddragon.canisback.com/set3/traits_transparent/bg_small.png);
    background-position: -95px;
    background-repeat: no-repeat;
}

.tile-trait.chromatic{
    width: 25px;
    height: 25px;
    background: url(http://ddragon.canisback.com/set3/traits_transparent/bg_small.png);
    background-position: -119px;
    background-repeat: no-repeat;
}

.tile-gold{
    background-image: url(../img/gold.png);
    background-size: 24px;
    width: 40px;
    height: 24px;
    background-repeat: no-repeat;
    padding-left: 20px;
    padding-top: 3px;
}