.board{
    display:inline-block;
    border-radius: 5px;
    border: 2px solid black;
    margin:3px;
    min-height:75px;
    padding-left:5px;
    padding-right:5px;
}

.board_champions{
    display:inline-block;
}

.board_traits{
    display:inline-block;
}
