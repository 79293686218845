
.shop-table>tbody>tr>td{
    vertical-align: middle;
    text-align: center;
}

.shop-table-champion-header{
    padding:0px!important;
}

.shop-table-border-2{
    border-top: thick solid #089210;
}

.shop-table-border-3{
    border-top: thick solid #1079d6;
}

.shop-table-border-4{
    border-top: thick solid #bd14d6;
}

.shop-table-border-5{
    border-top: thick solid #d6a610;
}

.shop-table-border-carousel{
    border-right: 1px solid red;
}

.shop-table-border-phase{
    border-left: 1px solid black;
}

.shop-table-border-total{
    border-left: 2px solid blue;
}

.col-md-1-5{
    position: relative;
    min-height: 1px;
    padding-right: 5px;
    padding-left: 5px;
    
    float: left;
    width: 14%;
}
