
.drop{
    display:inline-block;
    float:right;
    background-color:white;
    color:blue;
}

.drop-active{
    font-size:24px;
    position: fixed;
    color: red;
    background-color: rgb(0,0,0,0.8);
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    z-index:100;
            transition: 0.2s;
    -webkit-transition: 0.2s;
}

.drop-active > .drop-caption{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

