.img-champion{
    width:45px;
    height:45px;
}

.tile-champion.img-champion{
    width:25px;
    height:25px;
}


.champion-border{
    margin:3px
}


.champion-border-1:before{
    position: absolute;
    content:"";
    width:45px;
    height:52px;
    background:url("../img/1stars.png");
    background-repeat:no-repeat; 
    background-position: bottom;
}

.champion-border-2:before{
    position: absolute;
    content:"";
    width:45px;
    height:52px;
    background:url("../img/2stars.png");
    background-repeat:no-repeat; 
    background-position: bottom;
}

.champion-border-3:before{
    position: absolute;
    content:"";
    width:45px;
    height:52px;
    background:url("../img/3stars.png");
    background-repeat:no-repeat; 
    background-position: bottom;
}


.tile-champion.champion-border-1:before{
    position: absolute;
    content:"";
    width: 37px;
    height: 30px;
    background:url("../img/1stars.png");
    background-repeat:no-repeat; 
    background-position: bottom;
    margin-left: -6px;
}

.tile-champion.champion-border-2:before{
    position: absolute;
    content:"";
    width: 37px;
    height: 30px;
    background:url("../img/2stars.png");
    background-repeat:no-repeat; 
    background-position: bottom;
    margin-left: -6px;
}

.tile-champion.champion-border-3:before{
    position: absolute;
    content:"";
    width: 37px;
    height: 30px;
    background:url("../img/3stars.png");
    background-repeat:no-repeat; 
    background-position: bottom;
    margin-left: -6px;
}