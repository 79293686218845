.col-md-1-5{
    position: relative;
    min-height: 1px;
    padding-right: 5px;
    padding-left: 5px;
    
    float: left;
    width: 14%;
}

.tile{
    border:1px solid black;
    height:100px;
    margin:2px;
}