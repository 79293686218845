
.bronze{
    width: 40px;
    height: 40px;
    background: url(http://ddragon.canisback.com/set3/traits_transparent/bg_medium.png);
    background-position: -75px;
    background-repeat: no-repeat;
}

.silver{
    width: 40px;
    height: 40px;
    background: url(http://ddragon.canisback.com/set3/traits_transparent/bg_medium.png);
    background-position: -114px;
    background-repeat: no-repeat;
}

.gold{
    width: 40px;
    height: 40px;
    background: url(http://ddragon.canisback.com/set3/traits_transparent/bg_medium.png);
    background-position: -152px;
    background-repeat: no-repeat;
}

.chromatic{
    width: 40px;
    height: 40px;
    background: url(http://ddragon.canisback.com/set3/traits_transparent/bg_medium.png);
    background-position: -190px;
    background-repeat: no-repeat;
}
