.view-bar{
    display:inline-block;
}

.top-info{
    display:inline-block;
    vertical-align: middle;
}
.top-info > div{
    display:inline-block;
    margin:10px;
}

.top-rerolls{
    background-image: url(../img/reroll.png);
    background-size: 25px;
    width: 50px;
    height: 25px;
    background-repeat: no-repeat;
    padding-left: 29px;
    padding-top: 3px;
}

.top-xp-ups{
    background-image: url(../img/xp_up.png);
    background-size: 25px;
    width: 50px;
    height: 25px;
    background-repeat: no-repeat;
    padding-left: 29px;
    padding-top: 3px;
}